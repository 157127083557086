<template>
  <v-card width="100%">
    <v-card-title>
      Zone Information
    </v-card-title>
    <v-card-text>
      <v-simple-table>
        <tbody>
          <tr>
            <td>Zone ID</td>
            <td>{{ info.zone_id }}</td>
          </tr>
          <tr>
            <td>Name</td>
            <td>
              <br>
              <v-text-field v-model="info.name" label="Name" solo @change="onModified"></v-text-field>
            </td>
          </tr>
          <tr>
            <td>Region</td>
            <td>{{ info.region_id }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td>
              <br />
              <v-select
                v-model="info.status"
                :items="zoneStatusOptions"
                label="Zone delivery status"
                solo
                dense
                :background-color="statusColorLookup[info.status]"
                @change="onModified"
              ></v-select>
            </td>
          </tr>
          <tr>
            <td>Contained points</td>
            <td>
                {{ info.n_contained }}
            </td>
          </tr>
          <tr>
            <td>Has delivery location</td>
            <td>
              <v-chip v-if="info.delivery_position" color="green" small>
                YES
              </v-chip>
              <v-chip v-else color="orange" small @click="addDeliveryPoint">
                NO - ADD
              </v-chip>
            </td>
          </tr>
          <tr></tr>
        </tbody>
      </v-simple-table>

      <v-list-item v-if="info.notes.length > 0"
        >Notes : {{ info.notes }}
      </v-list-item>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn medium color="error" @click="saveData">
        Save
      </v-btn>
      <v-btn medium color="orange" @click="deleteZone">
        Delete
      </v-btn>
      <v-btn medium @click="cancel">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "ZoneInfo",
  props: {
    info: {},
    modified: Boolean
  },
  data() {
    return {
      statusColorLookup: {
        init: "yellow",
        verified: "green",
        unverified: "orange",
        unavailable: "red"
      },
      zoneStatusOptions: ["init", "verified", "unverified", "unavailable"]
    }
  },
  computed: {
    localInfo() {
      return this.info
    }
  },
  methods: {
    addDeliveryPoint() {
      this.$emit("addDeliveryPoint")
    },
    saveData() {
      this.$emit("save", this.info)
    },
    onModified() {
      this.$emit("modified")
    },
    cancel() {
      this.$emit("cancel")
    },
    deleteZone() {
      this.$emit("deleteZone")
    }
  }
}
</script>
