<template>
  <v-card width="100%">
    <v-card-title
      v-for="item in info.g_formatted_address.split(',').slice(0, 1)"
      :key="item"
    >
      {{ item }}
    </v-card-title>
    <v-card-text>
      <span
        v-for="item in info.g_formatted_address.split(',').slice(1, 5)"
        :key="item"
        >{{ item }}</span
      >
      <v-simple-table > 
        <tbody>
          <tr>
            <td>Region</td>
            <td>{{ info.region_id }}</td>
          </tr>
          <tr>
            <td>Status</td>
            <td >
              <br>
              <v-select  
                v-model="info.status"
                :items="placeStatusOptions"
                label="Address delivery status"
                solo
                dense
                :background-color="statusColorLookup[info.status]"
                @change="onModified"
              ></v-select>
<!--              <v-chip small :color="statusColorLookup[info.status]">
                {{ info.status }} 
              </v-chip> -->
            </td>
          </tr>
          <tr>
            <td>Types</td>
            <td>
              <v-chip
                v-for="item in info.g_types"
                :key="item"
                x-small
                color="blue"
              >
                {{ item }}
              </v-chip>
            </td>
          </tr>
          <tr>
            <td>Distance from trailer</td>
            <td>{{ info.delivery_distance.toFixed(2) }} miles</td>
          </tr>
          <tr>
            <td>Delivery location adjusted</td>
            <td>
              <v-chip v-if="info.adjusted" color="green" small>
                YES
              </v-chip>
              <v-chip v-else color="orange" small>
                NO
              </v-chip>
            </td>
          </tr>
          <tr>
            <td>Has google bounds</td>
            <td>
              <v-chip v-if="info.g_hasbounds" color="green" small>
                YES
              </v-chip>
              <v-chip v-else color="orange" small>
                NO
              </v-chip>
            </td>
          </tr>
          <tr v-if="info.zone.length > 0">
            <td>Zone</td>
            <td>{{ info.zone }}</td>
          </tr>
          <tr></tr>
        </tbody>
      </v-simple-table>

      <v-list-item v-if="info.notes.length > 0"
        >Notes : {{ info.notes }}
      </v-list-item>
    </v-card-text>
    <v-card-actions class="justify-center">
      <v-btn medium color="error" @click="saveData">
        Save
      </v-btn>
      <v-btn medium @click="cancel">
        Cancel
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "PlaceInfo",
  props: {
    info: {},
    modified: Boolean
  },
  data() {
    return {
      statusColorLookup: {
        init: "yellow",
        verified: "green",
        unverified: "orange",
        unavailable: "red"
      },
      placeStatusOptions:[
        'init','verified','unverified','unavailable'
      ]
    }
  },
  computed: {},
  watch: {
    modified(newm, oldm) {
        this.info['adjusted'] = true
        if (this.info['status'] === 'init') {
          this.info['status'] = 'unverified'
        }
    }
  },
  methods: {
    saveData() {
      this.$emit("save", this.info)
    },
    onModified() {
      this.$emit("modified")
    },
    cancel() {
      this.$emit("cancel")
    }
  }
}
</script>
