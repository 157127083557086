<template>
  <v-container id="google-maps" fluid tag="section">
    <v-col cols="12" md="12">
      <v-sheet min-height="70vh" height="600px">
        <Map />
      </v-sheet>
    </v-col>
    <v-col cols="12" md="6" />
  </v-container>
</template>

<script>
import Map from "../component/map"
import { mapGetters } from "vuex"

export default {
  ...mapGetters(['regionInfo']),
  components: {
    Map
  },
  data: () => ({})
}
</script>
