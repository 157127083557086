<template>
  <v-container style="height: 100%; width:100%;" class="ma-0 pa-0">
    <v-row align="center">
      <v-col cols="12" md="3">
        <v-list-item two-line>
          <v-list-item-content>
            <v-list-item-title class="headline">
              {{ regionInfo.name }}
            </v-list-item-title>
            <v-list-item-subtitle>{{ regionInfo.region_id }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </v-col>
      <v-col cols="12" md="8" >
        <v-row align="center" justify="center">
            <v-col cols="12" md="7">
            <v-select v-model="deliveryPointsShown" dense :items="deliveryPlaceItems" item-color="blue"
                      chips label="Delivery Points Shown" multiple solo @change="dpsUpdate" ></v-select>
          </v-col>
          <v-col cols="12" md="2">
            <v-switch v-model="showRadius" dense label="Radius" color="blue" :value="showRadius"></v-switch>
          </v-col>
          <v-col cols="12" md="2">
              <v-switch v-model="showZones" dense label="Zones" color="blue" :value="showZones"></v-switch>
          </v-col>
          <v-col cols="12" md="1">
            <v-btn small color="primary" @click="addZone">
              Add Zone
            </v-btn>
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
                      <v-col cols="12" md="7">
                        <span></span>
                      </v-col>
                    <v-col cols="12" md="3">
            <v-switch v-model="enableTempRadius" dense label="View Temp Radius" color="blue" :value="enableTempRadius"></v-switch>
          </v-col>
          <v-col cols="12" md="2">
               <v-text-field :disabled="!enableTempRadius" v-model="tempRadius" value="2.0" type="number" label="Temporary Radius" ></v-text-field>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    
    <v-row style="height: 95%; width:100%;" >
      <v-col cols="12" md="8" >
        <gmaps-map 
          :options="mapOptions"
          @zoom-changed="zoomChanged"
          @mounted="setMapInstance"
        >
       <gmaps-marker id='selectedMarker' ref="selectedMarker" 
          :options="selectedMarkerOptions"
        /> 
       <gmaps-marker 
          :options="selectedMarkerHomeOptions"
          :icon="{ url: require('@/assets/home.svg') }"

        /> 

        <gmaps-circle v-if="showRadius" :center="regionInfo.geometry" fill-opacity="0.1" z-index="1"
                      :radius="regionInfo.geometry.radius*1609.344" stroke-color="gray" fill-color="green" />
        <gmaps-circle v-if="enableTempRadius" :center="regionInfo.geometry" fill-opacity="0.1" z-index="1"
                      :radius="tempRadius*1609.344" stroke-color="gray" fill-color="yellow" />


        <div v-if="deliveryPointsShown.length>0">
          <gmaps-marker 
            v-for="(m, index) in markers"
            :key="index"
            :icon="{ url: require('@/assets/target.svg') }"
            :options="m.options"
            :position="m.position"
            @click="toggleInfoWindow(m.placeIdx, m.place_id)"
          />
        </div>
      <!-- fillColor="#FF6700" -->
      <div v-if="showZones">
      <gmaps-polygon v-for="(m,index) in zones" :key="index"
                     z-index="10"
                    :path="m.path"
                    :fill-color="statusColorLookup[m.status]"
                    stroke-weight=1
                    fill-opacity=0.2
                    :visible="m.visible"
                    @click="zoneClicked(index, m)"
                  >
      </gmaps-polygon>
      </div>
      <gmaps-polygon v-if="selectedZoneData!=null"
                    id="selectedZone"
                    ref="selectedZone" z-index="20"
                    :options="selectedZoneOptions"
                    fill-color="lime"
                    draggable
                    editable
                    :path="selectedZoneData.path"
                    stroke-weight=1
                    fill-opacity=0.3
                    @click="selectedZoneClicked(selectedZoneData)"
                    @path-changed="selectedZoneChanged"
                    @drag="selectedZoneChanged"
                  >
      </gmaps-polygon>

        </gmaps-map>
      </v-col>
      <v-col>
        <v-row>
            <input id="autocomplete" ref="autocomplete" placeholder="Search for address" />
        </v-row>
        <v-row v-if="placeData!=null" justify="center">
          <v-sheet>
            <place-info :info="placeData" :modified="modified" @modified="onDataModified" @save="saveUpdatedData" @cancel="cancelUpdatedData"></place-info>
          </v-sheet>
        </v-row>
        <v-row v-if="selectedZoneData!=null" justify="center">
          <v-sheet>
            <zone-info :info="selectedZoneData" :modified="modified" 
                        @addDeliveryPoint="onzdpAdded" @modified="onZoneDataModified" 
                        @deleteZone="deleteZone"
                        @save="saveUpdatedZoneData" @cancel="cancelUpdatedZoneData"></zone-info>
          </v-sheet>
        </v-row>

      </v-col>
    </v-row>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import PlaceInfo from './PlaceInfo'
import ZoneInfo from './ZoneInfo'
import axios from "axios"
import { gmapsMap, gmapsMarker, gmapsPolygon, gmaps, gmapsCircle, gmapsPolyline } from "x5-gmaps" // import from 'x5-gmaps' for plugin
import { mapGetters } from "vuex"
import LatLon from 'geodesy/latlon-spherical.js'

export default {
  name: "App",
  components: {
    gmapsMap,
    gmapsMarker,
    gmapsPolygon,
    PlaceInfo,
    ZoneInfo,
    gmapsCircle,
    gmapsPolyline
  },
  data() {
    return {
      deliveryPointsShown: [],
      enableTempRadius: false,
      tempRadius: 2.0,
      modified: false,
      showZones: true,
      showRadius: true,
      regionCenter: null,
      placeData: null,
      autocomplete: null,
      statusTypeCount: {init:0,verified:0,unverified:0,unavailable:0,'Zone Points':0},
      markers: [],
      localPlaceData: [],
      selectedMarkerInit: false,
      zones: [],
      map: null,
      viewports: [],
      mapZoomLevel: 0,
      polygonOptions: {
        fillColor: "#777"
      },
      markerOptions: {
        draggable: false
      },
      selectedMarkerPosition: { lat: 39.606994, lng: -84.16283 },
      selectedPlaceId: null,
      selectedZoneIdx: -1,
      selectedZoneId: null,
      selectedPlaceIdx: 0,
      selectedZoneData: null,
      selectedMarkerOptions: {
        draggable: true,
        visible: false,
        position: { lat: 39.606994, lng: -84.16283 }
      },
      selectedZoneOptions: {
        draggable: true,
        visible: false,
      },
      selectedMarkerHomeOptions: {
        draggable: false,
        visible: false,
        position: { lat: 39.606994, lng: -84.16283 }
      },
      mapOptions: {
        center: { lat: 39.606994, lng: -84.16283 },
        zoom: 15,
        scrollwheel: true,
        draggable: true
      },
      statusColorLookup: {
        init: "yellow",
        verified: "green",
        unverified: "orange",
        unavailable: "red"
      },

    }
  },
  computed: {
    deliveryPlaceItems() {
      return [
        {text:'init - '+this.statusTypeCount.init,value:'init'},
        {text:'verified - '+this.statusTypeCount.verified,value:'verified'},
        {text:'unverified - '+this.statusTypeCount.unverified, value:'unverified'},
        {text:'unavailable - '+this.statusTypeCount.unavailable, value:'unavailable'},
        {text:'Zone Points - '+this.statusTypeCount['Zone Points'], value:'Zone Points'}]
    },

    ...mapGetters(["regionId", "regionInfo"]),
    rinfo() {
      this.mapOptions.center.lat = this.regionInfo.geometry.lat;
      this.mapOptions.center.lng = this.regionInfo.geometry.lng;
      return this.regionInfo
    },
    rid() {
      return this.regionId
    },
  },
  watch: {
    rid(newRegion, oldRegion) {
      console.log("region id updated: "+newRegion)
      this.readRegionData()
    },
    rinfo(newRegionInfo, oldRgionInfo) {
      this.regionCenter = {lng:newRegionInfo.geometry.lng, lat:newRegionInfo.geometry.lat}
      if (newRegionInfo.region_id !== oldRgionInfo.region_id) {
        this.map.setCenter(this.regionCenter)
      }
    }
  },
  mounted() {
    gmaps().then(maps => {
      this.maps = maps
      this.$GMaps().then(maps => {
        // this.places = new maps.places.PlacesService(map)
        this.autocomplete = new maps.places.Autocomplete(
          this.$refs.autocomplete
        )
        this.autocomplete.addListener("place_changed", this.placeChanged)
        this.geocoder = new maps.Geocoder()
      })
    })
    this.readRegionData()

     /*
    axios
      .get('http://192.168.1.4/polygons.json')
      .then(response => {
        this.paths = response.data['data']
        // response.data["data"].forEach(this.addpolygon)
      })
    axios
      .get('http://192.168.1.4/viewports.json')
      .then(response => {
        this.viewports = response.data['data']
        // response.data["data"].forEach(this.addpolygon)
      }) */
  },
  methods: {
    readRegionData() {
      axios
        .get(window.apiurl+'dmap'+'/'+this.regionId)
        .then(response => {
          this.localPlaceData = []
          this.zones = []
          response.data["places"].forEach(this.addmarker)
          response.data["zones"].forEach(this.pushZone)
          if (this.$route.params.address !== undefined) {
            this.geocoder.geocode({address:this.$route.params.address})
              .then(response => {
                console.log('geo', response)
                this.autocomplete.set('place', response.results[0])
              })
          }
        }) 
    },
    programaticAutoSelect() {
      var input = document.getElementById('autocomplete');
      input.focus();
      selectFirstResult();
    },
    pushZone(d) {
      this.zones.push(d)
    },
    onDataModified() {
      this.modified = true
    },
    onZoneDataModified() {
      this.modified = true
    },
    selectedZoneChanged(newpath) {
      this.modified = true
    },
    zoneClicked(idx,zd) {
      if (zd.zone_id !== this.selectedZoneId) {
        // show old one
        if (this.selectedZoneIdx>=0) {
          this.zones[this.selectedZoneIdx].visible = true
        }
        zd.visible = false
        this.selectedZoneIdx = idx
        this.selectedZoneId = zd.zone_id
        this.modified = false
        this.selectedZoneData = { ...zd}

        if (zd.delivery_position) {
          this.selectedMarkerPosition = zd.delivery_position
          this.selectedMarkerOptions.position = this.selectedMarkerPosition
          this.selectedMarkerOptions.visible = true
          if (!this.selectedMarkerInit){
            this.selectedMarkerInit = true
            this.$refs.selectedMarker.marker.addListener('position_changed', () => {
              this.selectedMarkerDrag()
            })
          } 

        } else {
          this.selectedMarkerOptions.visible = false

        }
        console.log(zd)
      }
    },
    selectedZoneClicked(zd) {
    },
    onzdpAdded() {
      let vertices = this.$refs.selectedZone.polygon.getPath()
      let p = []
      for (let i = 0; i < vertices.getLength(); i++) {
        p.push(vertices.getAt(i))
      }
      p = JSON.parse(JSON.stringify(p))
      let minlat = p[0].lat
      let minlng = p[0].lng
      let maxlat = minlat
      let maxlng = minlng

      for (let i = 0; i < p.length; i++) {
        minlat = Math.min(minlat, p[i].lat)
        minlng = Math.min(minlng, p[i].lng)
        maxlat = Math.max(maxlat, p[i].lat)
        maxlng = Math.max(maxlng, p[i].lng)
      }
      let center = {
        lat:  minlat + ((maxlat - minlat) / 2),
        lng:  minlng + ((maxlng - minlng) / 2)
      }
      console.log(center)
      this.selectedMarkerPosition = center
      this.selectedMarkerOptions.position = this.selectedMarkerPosition
      this.selectedMarkerOptions.visible = true

    },
    deleteZone(zoneId) {
      axios
        .delete(window.apiurl+'zones'+'/'+this.selectedZoneId)
        .then(response => {
          this.selectedMarkerOptions.visible = false
          this.selectedZoneData = null
          this.selectedZoneIdx = -1
          this.selectedZoneId = null
        })
    },
    saveUpdatedZoneData(updatedData) {
      let vertices = this.$refs.selectedZone.polygon.getPath()
      let newpath = []
      for (let i = 0; i < vertices.getLength(); i++) {
        newpath.push(vertices.getAt(i))
      }
      console.log(JSON.stringify(newpath))
      this.selectedZoneData.path = newpath
      this.zones[this.selectedZoneIdx].path = newpath
      this.zones[this.selectedZoneIdx].status = updatedData.status
      this.zones[this.selectedZoneIdx].name = updatedData.name
      const deliveryPos = this.$refs.selectedMarker.marker.position.toJSON()
      if (this.selectedMarkerOptions.visible) {
        this.zones[this.selectedZoneIdx].delivery_position = deliveryPos
      }

      axios
        .post(window.apiurl+'zones'+'/'+this.selectedZoneId,
              JSON.stringify(this.zones[this.selectedZoneIdx]), {
              headers: {
                    'Content-Type': 'application/json'
              }
            })
        .then(response => {
        })

      if (this.selectedZoneIdx>=0) {
        this.zones[this.selectedZoneIdx].visible = true
      }
      this.selectedMarkerOptions.visible = false
      this.selectedZoneData = null
      this.selectedZoneIdx = -1
      this.selectedZoneId = null
    },
    cancelUpdatedZoneData() {
      if (this.selectedZoneIdx>=0) {
        this.zones[this.selectedZoneIdx].visible = true
      }
      this.selectedZoneData = null
      this.selectedZoneIdx = -1
      this.selectedZoneId = null
      this.selectedMarkerOptions.visible = false
    },
    addZone() {
      axios
        .get(window.apiurl+'zoneid'+'/'+this.regionInfo.region_id)
                .then(response => {
                  let zoneId = response.data.zone_id
                  let o = this.map.getCenter().toJSON()
                  let newpath = [{lng:o.lng+0.0005, lat:o.lat+0.0005},
                                {lng:o.lng+0.0005, lat:o.lat-0.0005},
                                {lng:o.lng-0.0005, lat:o.lat-0.0005},
                                {lng:o.lng-0.0005, lat:o.lat+0.0005}]
                                
                  this.zones.push({
                                    path: newpath, 
                                    zone_id: zoneId, 
                                    name: zoneId, 
                                    status: 'init',
                                    region_id: this.regionInfo.region_id,
                                    notes: ''
                  })
        })

    },
    dpsUpdate() {
      // only show markers that are enabled
      console.log('dpsUpdate')
      this.checkVisibleMarkers()
    },
    getAddressData(x, y, z) {},
    cancelUpdatedData() {
      this.selectedMarkerOptions.visible = false
      this.selectedMarkerHomeOptions.visible = false
      this.placeData = null
      this.modified = false
      this.checkVisibleMarkers()
    },
    saveUpdatedData(updatedData) {
      console.log('save updated')
      const position = this.$refs.selectedMarker.marker.position.toJSON()
      this.localPlaceData[this.selectedPlaceIdx] = { ...updatedData }
      this.localPlaceData[this.selectedPlaceIdx].delivery_position = position
      this.localPlaceData[this.selectedPlaceIdx].visible = true
      let lpd = this.localPlaceData[this.selectedPlaceIdx]
      let update = {
        delivery_position: lpd.delivery_position,
        adjusted: lpd.adjusted,
        notes: lpd.notes,
        ref_url: lpd.ref_url,
        status: lpd.status,
        region_id: this.localPlaceData[this.selectedPlaceIdx].region_id
      }
      axios
        .post(window.apiurl+'places'+'/'+this.selectedPlaceId,
              JSON.stringify(update), {
              headers: {
                    'Content-Type': 'application/json'
              }
            })
        .then(response => {
        })
      this.selectedMarkerHomeOptions.visible = false
      this.selectedMarkerOptions.visible = false
      this.placeData = null
      this.modified = false
      this.checkVisibleMarkers()
    },
    addPoint(d,placeIdx) {
        this.markers.push({
          position: d.delivery_position,
          options: { draggable: false, visible: true, crossOnDrag: false },
          place_id: d.g_place_id,
          placeIdx: placeIdx
      })
    },
    placeChanged() {
      console.log("place changed")
      
      const place = this.autocomplete.getPlace()
      console.log(place)
      if (place.geometry) {
        // this.map.panTo(place.geometry.location)
        this.map.fitBounds(place.geometry.viewport)
        // find index in our place array
        let idx = -1
        for (var i=0; i<this.localPlaceData.length; i++) {
          if (this.localPlaceData[i]['g_place_id'] === place.place_id) {
            idx = i
            break
          }
        }
        if (idx > -1) {
          this.toggleInfoWindow(idx,place.place_id)
        } else {
          console.log('not found: ', place)
          let manualPlace = this.createManualPlace({ ...place})
          this.localPlaceData.push(manualPlace)
          idx = this.localPlaceData.length-1
          this.toggleInfoWindow(idx,place.place_id)
        }
        /* var listener = this.maps.event.addListener(this.map, "idle", () => { 
          this.map.setZoom(18);
          this.maps.event.removeListener(listener); 
        }); */

      }
    },
    getPlaceFromDB(newPlaceId) {
      axios.get(window.apiurl+'places/'+place.place_id)
        .then((response) => {
          this.placeData = response.data
        })
    },
    setMapInstance(map) {
      // console.log(map)
      this.map = map
      this.maps.event.addListener(map, "idle", this.checkVisibleMarkers)
    },
    checkVisibleMarkers() {
      console.log("idle")
      var bounds = this.map.getBounds()
      let markersShown = []
      this.markers = []
      this.statusTypeCount = {init:0,verified:0,unverified:0,unavailable:0,'Zone Points':0}
      for (let i = 0; i < this.localPlaceData.length; i++) {
        if (bounds.contains(this.localPlaceData[i].delivery_position)) {
          if (this.localPlaceData[i].zone !== '') {
            this.statusTypeCount['Zone Points'] += 1
            if (this.deliveryPointsShown.includes('Zone Points')) {
              this.addPoint(this.localPlaceData[i], i)
            }
          } else {
            this.statusTypeCount[this.localPlaceData[i].status] += 1
            if (this.deliveryPointsShown.includes(this.localPlaceData[i].status)) {
              this.addPoint(this.localPlaceData[i], i)
            }
          }
          if (this.mapZoomLevel > 17) {
          }
        } else {
          // this.markers[i].options.visible = false
        }
      }
      // this.markers = markersShown
    },
    zoomChanged(d) {
      this.mapZoomLevel = d
    },
    addmarker(d) {
      // console.log(d)
      /*
      this.markers.push({
        position: d.delivery_position,
        options: { draggable: false, visible: true, crossOnDrag: false }
      }) */
      this.localPlaceData.push(d)
    },
    addpolygon(d) {
      // console.log(d)
      this.markers.push(d)
    },
    selectedMarkerDrag() {
      const position = this.$refs.selectedMarker.marker.position.toJSON()

      if (position.lat !== this.selectedMarkerPosition.lat || position.lng !== this.selectedMarkerPosition.lng) {
        this.modified = true
      }
    },
    createManualPlace: function(gplace) {
      const trailer_loc = new LatLon(this.regionInfo.geometry.lat, this.regionInfo.geometry.lng)
      console.log(this.regionInfo)
      const delivery_loc = new LatLon(gplace.geometry.location.lat(), gplace.geometry.location.lng())
      return {
          delivery_distance: trailer_loc.distanceTo(delivery_loc)/1609.344,
          delivery_position: {
              lat: gplace.geometry.location.lat(),
              lng: gplace.geometry.location.lng()
          },
          g_place_id: gplace.place_id,
          g_types: JSON.parse(JSON.stringify(gplace.types)),
          g_formatted_address: gplace.formatted_address,
          g_geometry: JSON.parse(JSON.stringify(gplace.geometry)),
          g_address_components: JSON.parse(JSON.stringify(gplace.address_components)),
          g_hasbounds: false,
          g_object: {},
          adjusted: false,
          status: "unverified",
          notes: "",
          ref_url: "",
          update_ts: 0,
          source: "manual",
          region_id: this.regionId,
          zone: ""
      }
    },
    toggleInfoWindow: function(idx,placeId) {
      console.log('place id: '+placeId)
      this.selectedPlaceId = placeId
      this.modified = false
      // this.markers[idx].options.draggable = true
      // this.markers[idx].options.visible = false
      this.placeData = { ...this.localPlaceData[idx] }
      console.log(this.placeData)
      this.selectedMarkerPosition = this.placeData.delivery_position
      this.selectedMarkerOptions.position = this.selectedMarkerPosition
      this.selectedPlaceIdx = idx
      this.selectedMarkerOptions.visible = true

      this.selectedMarkerHomeOptions.position = this.placeData['g_geometry']['location']
      this.selectedMarkerHomeOptions.visible = true

      if (!this.selectedMarkerInit){
        this.selectedMarkerInit = true
        this.$refs.selectedMarker.marker.addListener('position_changed', () => {
          this.selectedMarkerDrag()
        })
      } 
    }
  }
}
</script>

<style>
#autocomplete {
  border-radius: 5px;
  border: 3px solid #73ad21;
  height: 40px;
  left: 40%;
  padding: 0 10px;
  top: 0;
  width: 100%;
  z-index: 1;
}


</style>
